<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>采购管理</el-breadcrumb-item>
      <el-breadcrumb-item>对公采购申请</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <div class="top">
        <el-button type="primary" @click="opentan()">采购申请</el-button>
        <div class="tops">
          <span>审核人：</span>
          <el-select v-model="auth_userss" placeholder="未选择">
            <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="shaishen(item.value)"></el-option>
          </el-select>
        </div>
        <div class="tops">
          <span>状态：</span>
          <el-select v-model="auth_status" placeholder="未选择">
            <el-option v-for="item in zhuang" :key="item.value" :label="item.name" :value="item.value" @click.native="zhuangtai(item.value)"></el-option>
          </el-select>
        </div>
      </div>
      <!-- 列表开始 -->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column sortable prop="date" label="日期" width="180"></el-table-column>
        <el-table-column prop="apply_user" label="采购申请人" width="180" align="center"></el-table-column>
        <el-table-column prop="goods_info" label="采购物品" width="180" align="center"></el-table-column>
        <el-table-column prop="auth_user_name" label="采购审核人" width="180" align="center"></el-table-column>
        <el-table-column prop="house_name" label="入库仓库" width="180" align="center"></el-table-column>
        <el-table-column prop="auth_status_desc" label="审核状态" width="180" align="center"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-view" @click="look(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <!-- 添加弹窗 -->
    <el-dialog title="采购申请单" :visible.sync="dialogFormVisible" :before-close="handleClose" width="68%">
      <!-- {{ form }} -->
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="采购审核人" prop="auth_user" label-width="100px">
          <el-select v-model="form.auth_user" placeholder="未选择" clearable>
            <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="choicedrug(item.value)"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="采购公司" prop="company_id" label-width="100px">
          <el-select v-model="form.company_id" placeholder="未选择" clearable>
            <el-option v-for="item in canggongsi" :key="item.value" :label="item.name" :value="item.value" @click.native="choicedrug(item.value)"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="入库仓库" label-width="100px" prop="house">
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入仓库关键字"
            v-model="form.house"
            :fetch-suggestions="cangvalidateCounts"
            value-key="name"
            @select="canghandleSelect($event)"
          ></el-autocomplete>
        </el-form-item>

        <!-- 采购人 -->
        <el-form-item label="采购人" label-width="100px" prop="user">
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入内容"
            v-model="form.user"
            :fetch-suggestions="caivalidateCounts"
            value-key="name"
            @select="caihandleSelect($event, '采购人')"
          ></el-autocomplete>
        </el-form-item>

        <!-- 动态增加表格单行 -->
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAddZJ">新增</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleDeleteZJ">删除</el-button>
          </el-col>
          <!-- {{ form }} -->
        </el-row>
        <!-- 表格 -->
        <el-table :data="form.goods" ref="zztable" @selection-change="handleSelectionChangezz" :row-class-name="zzTableRowClassName">
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column label="序号" type="index" width="50" />
          <el-table-column prop="name" label="配件名称">
            <template slot-scope="scope">
              <el-autocomplete
                :goods_id="'fItem' + scope.row.index"
                :trigger-on-focus="false"
                placeholder="请输入内容"
                v-model="scope.row.name"
                :fetch-suggestions="validateCounts"
                value-key="name"
                @select="handleSelect($event)"
                :debounce="300"
                @focus="focusSelect(scope.row.index)"
              ></el-autocomplete>
            </template>
          </el-table-column>
          <el-table-column prop="no" label="配件编号"></el-table-column>
          <el-table-column prop="type_desc" label="配件分类"></el-table-column>
          <el-table-column prop="supplier_id" label="供应商名称">
            <template slot-scope="scope">
              <el-select v-model="scope.row.supplier_id" placeholder="请选择" clearable>
                <el-option
                  v-for="item in scope.row.suppliers"
                  :key="item.supplier_id"
                  :label="item.supplier_name"
                  :value="item.supplier_id"
                  @click.native="gongs(item)"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="supplier_goods_no" label="供应商货物编号"></el-table-column>

          <el-table-column prop="unit" label="配件单位">
            <template slot-scope="scope">
              <el-select v-model="scope.row.unit" placeholder="未选择">
                <el-option v-for="item in danwei" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 配件数量 -->
          <el-table-column prop="apply_num" label="购买数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.apply_num" min="0" placeholder="请输入购买数量" @input="scope.row.apply_num = inputCheck(scope.row.apply_num)"></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="item_remark" label="备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.item_remark" min="0" placeholder="请输入备注" type="text"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quanxiao()">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="quanque()" @click="quanque()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="申请采购详情" :visible.sync="XiangVisible" :before-close="xiangClose" width="60%">
      <div class="tans">
        <div class="to">采购单完成进度 {{ complete_percent }} %</div>
      </div>

      <div class="tan">
        <div class="let">申请人</div>
        <div class="el-select"><el-input v-model="apply_user.name" disabled></el-input></div>
      </div>

      <div class="tan">
        <div class="let">采购公司</div>
        <div class="el-select"><el-input v-model="company.name" disabled></el-input></div>
      </div>

      <div class="tan">
        <div class="let">入库仓库</div>
        <div class="el-select"><el-input v-model="house.name" disabled></el-input></div>
      </div>

      <div class="tan">
        <div class="let">采购审批人</div>
        <div class="el-select"><el-input v-model="auth_user.name" disabled></el-input></div>
      </div>

      <div class="tan">
        <div class="let">采购人</div>
        <div class="el-select"><el-input v-model="purchase_user.name" disabled></el-input></div>
      </div>

      <div class="huolist">
        <el-table :data="items" stripe style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="goods_name" label="货物名称"></el-table-column>
          <el-table-column prop="goods_no" label="货物编号"></el-table-column>
          <el-table-column prop="goods_type" label="货物分类"></el-table-column>
          <el-table-column prop="goods_desc" label="货物描述"></el-table-column>
          <el-table-column prop="supplier_name" label="供应商名称"></el-table-column>
          <el-table-column prop="supplier_goods_no" label="供应商货物编号"></el-table-column>
          <el-table-column prop="apply_num" label="数量"></el-table-column>
          <el-table-column prop="unit" label="单位"></el-table-column>
          <el-table-column prop="item_remark" label="备注"></el-table-column>
        </el-table>
      </div>

      <div class="auth_list">
        <div class="biaoti">对公采购申请审批详情 ↓</div>
        <el-table :data="auth_list" stripe style="width: 60%" border>
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="user_name" label="审批人"></el-table-column>
          <el-table-column prop="status_desc" label="审批状态"></el-table-column>
          <el-table-column prop="time" label="审批时间"></el-table-column>
          <el-table-column prop="remark" label="审批备注"></el-table-column>
          <!-- <el-table-column prop="goods_type" label="变更事项"></el-table-column> -->
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="XiangVisible = false">取 消</el-button>
        <el-button type="primary" @click="XiangVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Drop } from '../../../api/slogong'
import Fenye from '../../../components/Fenye.vue'
import { Shopsou, Cangsou, Shencai, Shenheren, Shenqinglist, Canggongsi, Shenxiang } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      xiangqing: {},
      apply_user: {},       // 详情申请人
      auth_user: {},        // 详情审核人
      purchase_user: {},    // 详情采购人
      company: {},          // 详情采购公司
      house: {},            // 入库仓库
      XiangVisible: false,  // 详情弹窗
      complete_percent: '', // 详情采购完成进度
      items: [],            // 详情采购明细
      auth_list: [],        // 详情审批表格
      auth_status: -1,
      auth_userss: '',      // 审核人筛选
      tableData: [],        // 列表数据
      focusIndex: '',
      dialogFormVisible: false, // 弹窗控制
      form: {
        auth_user: '',
        house_id: '',
        house: '',
        purchase_user: '',  // 采购人id
        company_id: '',     // 采购公司id
        user: '',
        goods: []
      },
      shoplist: [],        // 商品搜索列表
      userlist: [],        // 仓库列表
      caigoulist: [],      // 采购人列表
      renlist: [],         // 人员列表
      canggongsi: [],      // 仓库采购公司列表
      // 表单验证规则
      rules: {
        auth_user: [{ required: true, message: '请选择采购审核人', trigger: 'change' }],
        company_id: [{ required: true, message: '请选择采购公司', trigger: 'change' }],
        user: [{ required: true, message: '请选择采购人', trigger: ['blur', 'change'] }],
        house: [{ required: true, message: '请选择入库仓库', trigger: ['blur', 'change'] }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      danwei: [
        { name: '请选择', value: '' },
        { name: '个', value: 1 },
        { name: '包', value: 2 },
        { name: '公斤', value: 3 },
        { name: '克', value: 4 },
        { name: '套', value: 5 }
      ],
      zhuang: [
        { name: '全部', value: -1 },
        { name: '待处理', value: 0 },
        { name: '已通过', value: 1 },
        { name: '未通过', value: 2 },
        { name: '审批中', value: 3 },
        { name: '取消', value: 4 }
      ]
    }
  },
  mounted() {
    this.Shenqinglists()
    this.Getrenlist()
    this.Canggongsilist()
  },
  methods: {
    xiangClose() {
      this.XiangVisible = false
    },

    // 查看详情
    look(id) {
      // console.log(id)
      this.XiangVisible = true
      this.Shenxiangqing(id)
    },

    // 供应商下拉选中
    gongs(val) {
      // console.log(val)
      this.form.goods[this.focusIndex - 1].supplier_goods_no = val.goods_no
      this.handleAddZJ()
    },

    // 选中采购人
    caihandleSelect(e, val) {
      // console.log(e, val)
      this.form.purchase_user = e.value
    },

    // 搜索负责人后的数据
    caivalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 输入框验证
    inputCheck(value) {
      return value
        .replace(/^0[0-9]+/, val => val[1])
        .replace(/^(\.)+/, '')
        .replace(/[^\d.]/g, '')
        .replace(/\.+/, '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },

    // 取消
    quanxiao() {
      this.form.house_id = ''
      this.form.house = ''
      this.form.auth_user = ''
      this.form.goods = []
      this.form.user = ''
      this.form.purchase_user = ''
      this.dialogFormVisible = false
      this.form.company_id = ''
    },

    focusSelect(index) {
      this.focusIndex = index
    },

    // 关闭弹窗
    handleClose() {
      this.quanxiao()
    },

    // 添加
    opentan() {
      this.dialogFormVisible = true
      // this.Getrenlist()
    },

    // 配件选中
    danya(val) {
      // console.log(val)
    },

    // 指定入库仓库
    chcang(val) {
      // console.log(val)
    },

    //添加行号
    zzTableRowClassName({ row, rowIndex }) {
      row.index = rowIndex + 1
    },

    // 多选框选中数据
    handleSelectionChangezz(selection) {
      this.idszz = selection.map(item => item.fid)
    },

    //新增专家行
    handleAddZJ() {
      let obj = {
        // goods_id: ''
      }
      this.form.goods.push(obj)
    },

    //删除专家行
    handleDeleteZJ() {
      let arr = this.$refs.zztable.selection
      const arr1 = []
      for (let i = 0; i < arr.length; i++) {
        arr1.push(arr[i].index)
      }
      //数字数组排序，因为选择框是根据先选择的顺序存到checkedDetail里面，所以需要排序
      arr1.sort(function (a, b) {
        return a - b
      })
      for (let i = 0; i < arr1.length; i++) {
        this.form.goods.splice(arr1[i] - i - 1, 1)
      }
    },

    // 人员选中
    choicedrug(e) {
      // console.log(e)
    },

    // 搜商品的数据
    validateCounts(queryString, cb) {
      this.Drops(queryString, cb)
    },
    // 搜索选中
    handleSelect(e, term) {
      console.log(e)
      this.form.goods[this.focusIndex - 1].unit = e.unit
      this.form.goods[this.focusIndex - 1].suppliers = e.suppliers
      // this.handleAddZJ()
      this.form.goods[this.focusIndex - 1].goods_id = e.value
      this.form.goods[this.focusIndex - 1].no = e.no
      this.form.goods[this.focusIndex - 1].type_desc = e.type_desc
    },

    // 搜索仓库
    cangvalidateCounts(queryString, cb) {
      this.Cang(queryString, cb)
    },

    // 搜索仓库选中
    canghandleSelect(e) {
      // console.log(e)
      this.form.house_id = e.value
    },

    // 确定添加
    quanque() {
      this.$refs['form'].validate(v => {
        if (v) {
          this.ClientInformations(this.form)
          this.$refs.form.resetFields()
          this.quanxiao()
        }
      })
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Shenqinglists()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Shenqinglists()
    },

    // 筛选审核人选中
    shaishen(e) {
      // console.log(e)
      this.Shenqinglists()
    },
    // 筛选状态
    zhuangtai(e) {
      // console.log(e)
      this.Shenqinglists()
    },

    //////////// 请求区////////////////

    // 人员列表
    async Getrenlist() {
      const { data } = await Shenheren()
      this.renlist = data.data
    },

    // 采购人
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.caigoulist = data.data
      cd(data.data)
    },

    // 商品搜索
    async Drops(name, cd) {
      const { data } = await Shopsou({ name })
      // console.log(data.data)
      this.shoplist = data.data
      cd(data.data)
    },

    // 仓库搜索
    async Cang(name, cd) {
      const { data } = await Cangsou({ name })
      // console.log(data.data)
      this.userlist = data.data
      cd(data.data)
    },
    // 添加申请
    async ClientInformations(v) {
      const { data } = await Shencai(v)
      this.getanpostfun(data, '添加', this.Shenqinglists())
    },

    // 列表
    async Shenqinglists() {
      const { data } = await Shenqinglist({ ...this.pageData, auth_user: this.auth_userss, auth_status: this.auth_status })
      // console.log(data)
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 仓库采购公司列表
    async Canggongsilist(v) {
      const { data } = await Canggongsi()
      // console.log(data);
      this.canggongsi = data.data
    },

    // 申请采购单详情
    async Shenxiangqing(id) {
      const { data } = await Shenxiang({ id })
      this.xiangqing = data.data
      console.log(this.xiangqing)
      this.apply_user = data.data.apply_user // 详情申请人
      this.auth_user = data.data.auth_user // 详情审核人
      this.purchase_user = data.data.purchase_user // 详情采购人
      this.company = data.data.company // 详情采购公司
      this.house = data.data.house // 入库仓库
      this.complete_percent = data.data.complete_percent // 详情采购完成进度
      this.items = data.data.items // 详情采购明细
      this.auth_list = data.data.auth_list // 详情审批表格
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`, this.handleClose())
      }
    }
  }
}
</script>
<style >
.top {
  display: flex;
  align-items: center;
}
.tops {
  margin-left: 30px;
}
.tan {
  display: flex;
}
.tans {
  text-align: right;
}
.let {
  width: 70px;
  margin-left: 15px;
  margin-right: 10px;
}
.to {
  /* width: 140px !important; */
  color: red;
  font-size: 18px;
  margin-right: 80px;
  position: absolute;
  top: 7%;
  right: 10%;
}
.huolist {
  width: 100%;
}
.biaoti {
  margin-top: 20px;
  font-size: 15px;
  color: red;
}
</style>